import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { returnEmptyIfNull } from "src/utils/utils";
import styles from "./PostsListingDetailed.module.scss";

const PostListingDetailed = ({ twoColumns, className, postEdges }) => {
	const getPostList = () => {
		const postList = [];
		postEdges.forEach(postEdge => {
			postList.push({
				path: postEdge.node.fields.slug,
				tags: postEdge.node.frontmatter.tags,
				userJourney: returnEmptyIfNull(
					postEdge.node.frontmatter.userJourney
				),
				h1title: postEdge.node.frontmatter.h1title,
				date: postEdge.node.fields.date,
				description: returnEmptyIfNull(
					postEdge.node.frontmatter.description
				),
				timeToRead: postEdge.node.timeToRead
			});
		});
		return postList;
	};

	let twoColumnsClass = "";
	if (twoColumns) {
		twoColumnsClass = styles.twoColumns;
	}

	// {post.userJourney.join(" / ")}
	const postList = getPostList();

	const renderedPostList = postList.map(post => (
		<div
			key={post.h1title}
			className={`${className} ${styles.postBlock} p-t-lg`}
		>
			<Link to={post.path} className="hoverLink">
				<h3 className="m-b-sm">{post.h1title}</h3>
			</Link>
			<p className="noPMargin">{post.description}</p>
			<div className="m-t-sm">
				<span className={`${styles.greyTag}`}>{post.date}</span>
				<span className={`${styles.greyTag}`}>
					{post.timeToRead} Min Read
				</span>
			</div>
			<Link to={post.path}>
				<div className="m-t-sm">Read more</div>
			</Link>
		</div>
	));
	return <div className={`${twoColumnsClass}`}>{renderedPostList}</div>;
};

PostListingDetailed.propTypes = {
	postEdges: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default PostListingDetailed;
